import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../sg-react/assets/images/logos/logo_official_1.png";
import { useUiContext } from "../../sg-react/context";
import { Checkbox, TextArea, TextField } from "../../sg-react/form";
import { useForm, useRequest } from "../../sg-react/hooks";
import { ValidationRule } from "../../sg-react/hooks/useForm";
import { Button, Card, Modal } from "../../sg-react/ui";
import screenshot_filters from "./assets/filters.png";
import screenshot_map from "./assets/map.png";
import screenshot_sg from "./assets/sg.png";
import french_fab from "./assets/french_fab.png";
import french_tech from "./assets/french_tech.png";
import './index.scss';

const VALIDATION = {
    email: [{ rule: ValidationRule.Required }],
    firstName: [{ rule: ValidationRule.Required }],
    lastName: [{ rule: ValidationRule.Required }],
    company: [{ rule: ValidationRule.Required }],
};

interface SurveyAnswer {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    field: string;
    comment: string;
    rgpd: boolean;
    allowEmail: boolean;
    shareData: boolean;
    viewData: boolean;
}

const Survey = () => {
    const { entity, validate, attachInput, reset } = useForm<SurveyAnswer>({});
    const { setToastError } = useUiContext();
    const [isModalRgpdVisible, setModalRgpdVisible] = useState<boolean>(false);
    const { t } = useTranslation();
    const request = useRequest();

    const handleSubmit = useCallback(() => {
        if (!validate(VALIDATION)) return;

        request.post<{ token: string }>('/surveys', entity, { loader: true, i18n: true, successMessage: 'success.answer_sent' })
            .then(() => reset())
            .catch((e) => {
                if ((e as any)?.response?.status === 429) {
                    setToastError(t('message:error.too_many_requests'));
                } else {
                    setToastError(t('message:error.server_error'));
                }
            });
    }, [entity, validate]);


    return (
        <Fragment>
            <Card id="survey">
                <div id="survey-title">
                    <div>L'annuaire industriel</div>
                    <div><span>par</span> SUPPLY-GRAPH<img src={logo} alt="" /></div>
                </div>
                <div id="survey-container">
                    <div id="survey-left">
                        <div>Aidez-nous à construire un annuaire regroupant tous les industriels français par secteur d’activité.</div>
                        <div>Accessible par tous les industriels<br />Gratuit pour tous les industriels<br /><br /></div>
                        <div>Vous êtes intéressés pour :</div>
                        <Checkbox {...attachInput('shareData')} label="Partager des données" />
                        <Checkbox {...attachInput('viewData')} label="Consulter des données" />
                        <div id="survey-form">
                            <div className="flex-row-equal-cols">
                                <TextField
                                    small
                                    {...attachInput('firstName')}
                                    label="Prénom *"
                                    autoComplete
                                />
                                <TextField
                                    small
                                    {...attachInput('lastName')}
                                    label="Nom *"
                                    autoComplete
                                />
                            </div>
                            <TextField
                                small
                                {...attachInput('email')}
                                label="Email professionnel *"
                                autoComplete
                            />
                            <div className="flex-row-equal-cols">
                                <TextField
                                    small
                                    {...attachInput('company')}
                                    label="Société *"
                                    autoComplete
                                />
                                <TextField
                                    small
                                    {...attachInput('field')}
                                    label="Secteur d'activité"
                                    autoComplete
                                />
                            </div>
                            <TextArea
                                small
                                {...attachInput('comment')}
                                label="Commentaire ou remarque"
                            />
                        </div>
                        <div id="survey-actions">
                            <div id="allow-email">
                                <Checkbox {...attachInput('allowEmail')} />
                                <div>Consentez-vous à être recontacté pour améliorer la précision de l'annuaire ?</div>
                            </div>
                            <div id="rgpd">
                                <Checkbox {...attachInput('rgpd')} />
                                <div>
                                    <span>En cochant cette case, j'autorise Supply-Graph à traiter les données recueillies pour la création d'un annuaire industriel.</span>
                                    <span>Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous à la <span className="link" onClick={() => setModalRgpdVisible(true)}>notice ci-jointe</span>.</span>
                                </div>
                            </div>
                            <Button color={!entity.rgpd ? 'disabled' : 'accent'} disabled={!entity.rgpd} label="Envoyer la réponse" onClick={handleSubmit} />
                        </div>
                    </div>
                    <div id="survey-right">
                        <div id="screenshots">
                            <img id="filters" src={screenshot_filters} alt="Supply-Graph" />
                            <img id="map" src={screenshot_map} alt="Supply-Graph" />
                            <img id="sg" src={screenshot_sg} alt="Supply-Graph" />
                        </div>
                        <div id="survey-footer">
                            <div><span>Supply-Graph</span></div>
                            <div><span>membre de</span><img src={french_fab} alt="" /><img src={french_tech} alt="" /></div>
                        </div>
                    </div>
                </div>
            </Card>
            {!!isModalRgpdVisible && (
                <Modal
                    type="small"
                    header="Gestion des données personnelles"
                    onClose={() => setModalRgpdVisible(false)}
                >
                    <div id="modal-rgpd">
                        <div>Les informations recueillies dans le questionnaire sont enregistrées dans un fichier informatisé par Supply-Graph. La base légale du traitement est le consentement.</div>
                        <div>Les données marquées par un astérisque dans le questionnaire doivent obligatoirement être fournies. Dans le cas contraire, votre inscription ne sera pas prise en compte.</div>
                        <div>Les données collectées seront communiquées aux seuls destinataires suivants : Supply-Graph pour toutes vos données, les utilisateurs de l'annuaire pour vos données basiques (Nom, Siret, localisation).</div>
                        <div>Elles sont conservées pendant une durée de 3 ans, renouvelable tacitement.</div>
                        <div>Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données.</div>
                        <div>Vous pouvez retirer à tout moment votre consentement au traitement de vos données.
                            Vous pouvez également vous opposer au traitement de vos données.
                            Vous pouvez également exercer votre droit à la portabilité de vos données.</div>
                        <div>Consultez le site <a href="https://cnil.fr/fr" target="_blank" rel="noreferrer">cnil.fr</a> pour plus d’informations sur vos droits.</div>
                        <div>Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter <a href="mailto:rgpd@supply-graph.io">rgpd@supply-graph.io</a>.</div>
                        <div>Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la <a href="https://cnil.fr/fr" target="_blank" rel="noreferrer">CNIL</a>.</div>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}

export default Survey;