import { ReactNode } from 'react';
import { HourglassIcon } from '../../icons';
import './index.scss';

export type ButtonColor = 'primary' | 'secondary' | 'accent' | 'success' | 'warning' | 'error' | 'menu' | 'disabled';

export interface ButtonProps {
    label?: string;
    icon?: ReactNode;
    className?: string;
    onClick?: () => void,
    disabled?: boolean,
    loading?: boolean,
    type?: 'button' | 'submit',
    color?: ButtonColor,
}

const Button = ({
    label,
    icon,
    className,
    onClick,
    disabled,
    loading,
    type,
    color,
}: ButtonProps) => (
    <button
        type={type ?? 'button'}
        className={`button button-${color} ${loading ? 'button-loading' : ''} ${className ?? ''}`}
        onClick={!disabled && !loading && type !== 'submit' ? onClick : undefined}
    >
        <span>{label ?? ''}</span>{icon}
        <HourglassIcon className="button-loading-icon" />
    </button >
);

export default Button;