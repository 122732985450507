import { Outlet } from "react-router-dom";
import './index.scss';

const Public = () => {
    return (
        <div id="public">
            <Outlet />
        </div>
    )
}

export default Public;
