import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Public from "./Public";
import Survey from "./survey";

const publicRoutes = [
    {
        path: "/",
        element: <Public />,
        children: [
            {
                path: '/',
                element: <Survey />,
            },
            {
                path: '/*',
                element: <Survey />,
            },
        ]
    },
];

const App = () => <RouterProvider router={createBrowserRouter(publicRoutes ?? [])} />;

export default App;